import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(3),
  },
  chip: {
    margin: theme.spacing(0.5),
    cursor: 'grab',
  },
  chipsContainer: {
    marginBottom: theme.spacing(2),
  },
  chipsInput: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default function ChipsList({ items = [], onUpdate, title }) {
  const classes = useStyles();
  const [newBenefitValue, setNewBenefitValue] = useState('');

  function handleDelete(chipToDelete) {
    const newItems = items.filter((item) => item !== chipToDelete);
    onUpdate(newItems);
  }

  function handleAdd() {
    const newItems = [...items, newBenefitValue];
    onUpdate(newItems);
    setNewBenefitValue('');
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleAdd(e.target.value);
    }
  }

  function handleDragEnd(result) {
    if (!result.destination) return;

    const reorderedItems = [...items];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    onUpdate(reorderedItems);
  }

  const getListStyle = (isDraggingOver) => ({
    display: 'flex',
    overflow: 'auto',
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box className={classes.chipsContainer}>
        <Typography variant='h6' gutterBottom>
          {title}
        </Typography>
        <Droppable droppableId='droppable' direction='horizontal'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable key={index} draggableId={String(index)} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <Chip label={item} onDelete={() => handleDelete(item)} className={classes.chip} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Box className={classes.chipsInput}>
          <TextField
            label='Add item'
            value={newBenefitValue}
            onKeyDown={handleKeyDown}
            onChange={(e) => setNewBenefitValue(e.target.value)}
          />
          <Button
            variant='contained'
            color='primary'
            disabled={!newBenefitValue}
            style={{
              marginLeft: '15px',
            }}
            startIcon={<AddIcon />}
            onClick={() => handleAdd()}
          >
            Add
          </Button>
        </Box>
        <Divider />
      </Box>
    </DragDropContext>
  );
}
