import createDataContext from './createDataContext';
import { api } from '../api/api';

const proofPopupsReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        proofPopups: action.payload,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const loadProofPopups = (dispatch) => async () => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.get('/proofPopups', {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to load proofPopups',
    });
  }
};

const createProofPopup = (dispatch) => async (data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.post(`/proofPopups`, data, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });

    return response.data;
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to create proofPopup.',
    });
  }
};

const updateProofPopup = (dispatch) => async (id, data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.patch(`/proofPopups/${id}`, data, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to update proofPopup.',
    });
  }
};

const deleteProofPopup = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.delete(`/proofPopups/${id}`, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to delete proofPopup.',
    });
  }
};

export const { Context, Provider } = createDataContext(
  proofPopupsReducer,
  {
    loadProofPopups,
    createProofPopup,
    updateProofPopup,
    deleteProofPopup,
  },
  {
    proofPopups: [],
    error: '',
    loading: false,
  }
);
