import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import { Context as CrossSellsContext } from '../../context/CrossSellsContext';
import { Context as CrossSellContext } from '../../context/CrossSellContext';
import { Context as UserContext } from '../../context/UserContext';
import { getPriceWithCurrency } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(4),
  },
  topButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
}));

export default function CrossSellsPage({ onRender }) {
  const classes = useStyles();
  const history = useHistory();

  // STATE
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [crossSellToDelete, setCrossSellToDelete] = useState(null);

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { crossSells, loading: crossSellsLoading, error: crossSellsError },
    loadCrossSells,
  } = useContext(CrossSellsContext);

  const {
    state: { crossSell, error: crossSellError },
    createCrossSell,
    deleteCrossSell,
  } = useContext(CrossSellContext);

  // EFFECTS
  useEffect(() => {
    if (user || crossSell) {
      loadCrossSells();
    }

    onRender('Cross-sells');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, crossSell]);

  function handleDeleteCrossSell(id) {
    setDeleteModalOpen(true);
    setCrossSellToDelete(id);
  }

  async function handleCreateCrossSell() {
    const newCrossSell = await createCrossSell();
    history.push(`/crossSells/${newCrossSell._id}`);
  }

  if (crossSellsError || crossSellError) {
    return <Error error={crossSellsError || crossSellError} />;
  }

  if (crossSellsLoading) {
    return <Loading />;
  }

  function renderCrossSellsList() {
    if (!crossSells.length) {
      return null;
    }

    return (
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell align='right'>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {crossSells.map((row) => (
              <TableRow key={row._id} hover>
                <TableCell component='th' scope='row'>
                  <Link
                    component='button'
                    variant='body2'
                    onClick={() => {
                      history.push(`/crossSells/${row._id}`);
                    }}
                  >
                    {row._id}
                  </Link>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{getPriceWithCurrency(row.price)}</TableCell>
                <TableCell align='right'>
                  <IconButton
                    color='secondary'
                    aria-label='Delete review'
                    component='span'
                    onClick={() => handleDeleteCrossSell(row._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box>
          <Typography variant='h6' gutterBottom>
            Are you sure you want to delete this cross-sell?
          </Typography>
        </Box>
        <Box className={classes.modalButtons}>
          <Button
            variant='contained'
            color='primary'
            style={{ marginRight: '10px' }}
            onClick={async () => {
              setDeleteModalOpen(false);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={async () => {
              await deleteCrossSell(crossSellToDelete);
              setDeleteModalOpen(false);
            }}
          >
            Yes
          </Button>
        </Box>
      </Modal>
      <Container className={classes.container}>
        <Box className={classes.topButtons}>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            onClick={() => handleCreateCrossSell()}
          >
            Add
          </Button>
        </Box>
        {renderCrossSellsList()}
      </Container>
    </>
  );
}
