import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ImageInput from '../../components/ImageInput/ImageInput';
import Error from '../../components/Error/Error';
import { Context as UserContext } from '../../context/UserContext';
import { Context as FreeProductContext } from '../../context/FreeProductContext';
import { Box, Button, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(3),
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  products: {
    display: 'flex',
  },
  productsIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
  },
  pageActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

export default function FreeProductPage({ onRender }) {
  // VARS
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  // STATE
  const [stickyId, setStickyId] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [offerId, setOfferId] = useState('');
  const [billingModelId, setBillingModelId] = useState('');
  const [stock, setStock] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [active, setActive] = useState(true);

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { freeProduct, loading: freeProductLoading, error: freeProductError },
    loadFreeProduct,
    updateFreeProduct,
    createFreeProduct,
  } = useContext(FreeProductContext);

  // EFFECTS
  useEffect(() => {
    if (user) {
      loadFreeProduct(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (freeProduct) {
      onRender(`Free product - ${freeProduct.name}`);
      // Set values after freeProduct is loaded
      setName(freeProduct.name);
      setTitle(freeProduct.title);
      setDescription(freeProduct.description);
      setStickyId(freeProduct.stickyId);
      setStock(freeProduct.stock);
      setImageSrc(freeProduct.imageSrc);
      setActive(freeProduct.active);
      setOfferId(freeProduct.offerId);
      setBillingModelId(freeProduct.billingModelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeProduct]);

  if (freeProductError) {
    return <Error error={freeProductError} />;
  }

  if (!freeProduct) {
    return null;
  }

  function handleFreeProductUpdate(update) {
    updateFreeProduct(id, update);
  }

  function renderPageActionButtons() {
    async function onCloneClick() {
      const clone = freeProduct;
      delete clone.createdAt;
      delete clone._id;
      delete clone.__v;
      clone.name += ' CLONE';

      try {
        const created = await createFreeProduct(clone);
        history.push(`/freeProducts/${created._id}`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      }
    }

    return (
      <Box className={classes.pageActionButtons}>
        <Button
          variant='contained'
          color='primary'
          endIcon={<ContentCopyIcon />}
          onClick={() => onCloneClick()}
        >
          Clone
        </Button>
      </Box>
    );
  }

  return (
    <Container maxWidth='lg' className={classes.container}>
      {freeProductLoading && <LinearProgress className={classes.progress} />}
      {renderPageActionButtons()}
      <Paper className={classes.paper}>
        <FormControlLabel
          style={{
            marginBottom: '24px',
          }}
          control={
            <Checkbox
              checked={active}
              onChange={(e) =>
                handleFreeProductUpdate({
                  active: e.target.checked,
                })
              }
              name='Active'
              color='primary'
            />
          }
          label='Active'
        />
        <TextField
          label='Id'
          value={freeProduct._id}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          className={classes.textField}
        />
        <TextField
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() =>
            handleFreeProductUpdate({
              name,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Sticky PID'
          value={stickyId}
          helperText='Note: Leave it empty for sold out products'
          onChange={(e) => setStickyId(e.target.value)}
          onBlur={() =>
            handleFreeProductUpdate({
              stickyId,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Sticky Offer Id'
          value={offerId}
          onChange={(e) => setOfferId(e.target.value)}
          onBlur={() =>
            handleFreeProductUpdate({
              offerId,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Billing Model Id'
          value={billingModelId}
          onChange={(e) => setBillingModelId(e.target.value)}
          onBlur={() =>
            handleFreeProductUpdate({
              billingModelId,
            })
          }
          fullWidth
          className={classes.textField}
        />

        <TextField
          label='Title'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={() =>
            handleFreeProductUpdate({
              title,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={() =>
            handleFreeProductUpdate({
              description,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Stock'
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          onBlur={() =>
            handleFreeProductUpdate({
              stock,
            })
          }
          fullWidth
          className={classes.textField}
        />

        <ImageInput
          label='Image source url'
          value={imageSrc}
          onChange={(e) => setImageSrc(e.target.value)}
          onBlur={(e) =>
            handleFreeProductUpdate({
              imageSrc,
            })
          }
        />
      </Paper>
    </Container>
  );
}

FreeProductPage.propTypes = {
  onRender: PropTypes.func,
};
