/* eslint-disable jsx-a11y/alt-text */
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {},
  container: {
    display: 'flex',

    marginBottom: theme.spacing(3),
  },
  image: {
    marginLeft: theme.spacing(3),
  },
}));

export default function ImageInput({ onChange, onBlur, label, value, name, helperText, className }) {
  const classes = useStyles();

  return (
    <Box className={`${classes.container} ${className}`}>
      <TextField
        name={name}
        helperText={helperText}
        label={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        className={classes.textField}
      />
      <img src={value} height='38' className={classes.image} />
    </Box>
  );
}
