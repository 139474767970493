import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Wysiwyg({ initialValue, onBlur, height = 500 }) {
  const [value, setValue] = useState(initialValue || '');

  const handleBlur = (previousRange, source, editor) => {
    if (source === 'silent') return;
    onBlur(editor.getHTML());
  };

  return (
    <div style={{ marginBottom: '60px' }}>
      <ReactQuill
        value={value}
        onChange={(content, delta, source, editor) => {
          setValue(content);
        }}
        onBlur={handleBlur}
        style={{ height }}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            ['clean'],
          ],
        }}
        formats={['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link', 'image']}
      />
    </div>
  );
}

export default Wysiwyg;
