/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ImageInput from '../../components/ImageInput/ImageInput';
import Wysiwyg from '../../components/Wysiwyg/Wysiwyg';
import Error from '../../components/Error/Error';
import { Context as UserContext } from '../../context/UserContext';
import { Context as ListicleContext } from '../../context/ListicleContext';

import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from '../../api/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '26px',
  },
  container: {
    marginTop: theme.spacing(3),
  },
  progress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  product: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(3),
    borderBottom: '1px solid #eee',
    paddingBottom: theme.spacing(2),
  },
  productLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  productIcons: {
    display: 'flex',
    justifyContent: 'center',
  },
  productIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  pageActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

export default function ListiclePage({ onRender }) {
  // VARS
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  // STATE
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [headline, setHeadline] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [authorPhoto, setAuthorPhoto] = useState('');
  const [heroImage, setHeroImage] = useState('');
  const [products, setProducts] = useState([]);
  const [logo, setLogo] = useState('');
  const [favicon, setFavicon] = useState('');
  const [loading, setLoading] = useState(false);

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { listicle, loading: listicleLoading, error: listicleError },
    loadListicle,
    updateListicle,
  } = useContext(ListicleContext);

  // EFFECTS
  useEffect(() => {
    if (user) {
      loadListicle(id);
    }
  }, [user, id]);

  // ListiclePage.js
  useEffect(() => {
    if (listicle) {
      onRender(`Listicle - ${listicle.name}`);
      // Set values after listicle is loaded
      setName(listicle.name);
      setSlug(listicle.slug);
      setHeadline(listicle.headline);
      setAuthorName(listicle.authorName);
      setAuthorPhoto(listicle.authorPhoto);
      setHeroImage(listicle.heroImage);
      setLogo(listicle.logo);
      setFavicon(listicle.favicon);
    } else {
      // Reset state when listicle is null
      setName('');
      setSlug('');
      setHeadline('');
      setAuthorName('');
      setAuthorPhoto('');
      setHeroImage('');
      setLogo('');
      setFavicon('');
      setProducts([]); // Reset products here
    }
  }, [listicle]);

  useEffect(() => {
    async function fetchProducts() {
      try {
        setLoading(true);
        const response = await api.get(`/listicles/${listicle._id}/products`);
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }

    if (listicle) {
      fetchProducts();
    }
  }, [listicle]);

  if (listicleError) {
    return <Error error={listicleError} />;
  }

  if (!listicle) {
    return null;
  }

  function handleListicleUpdate(update) {
    updateListicle(id, update);
  }

  function handleWysiwygBlur(name, value) {
    handleListicleUpdate({
      [name]: value,
    });
  }

  function renderPageActionButtons() {
    async function onCloneClick() {
      setLoading(true);

      try {
        // Call the new backend route to clone the listicle
        const response = await api.post(`/listicles/${listicle._id}/clone`);
        const created = response.data;

        history.push(`/listicles/${created._id}`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      } finally {
        setLoading(false);
      }
    }

    return (
      <Box className={classes.pageActionButtons}>
        <Button
          variant='contained'
          color='primary'
          endIcon={<ContentCopyIcon />}
          onClick={onCloneClick}
          disabled={loading}
        >
          Clone
        </Button>
      </Box>
    );
  }

  function renderProducts() {
    // Helper functions

    // Function to update a product
    async function updateProduct(productId, data) {
      try {
        const response = await api.patch(`/listicleProducts/${productId}`, data);
        // Update the product in the local state
        setProducts((prevProducts) =>
          prevProducts.map((product) => (product._id === productId ? response.data : product))
        );
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }

    // Function to add a new product
    async function handleAddProduct() {
      const newProduct = {
        title: '',
        link: '',
        image: '',
        order: products.length + 1,
        ctaText: 'Learn More About',
        description: '',
        listicleId: listicle._id,
      };

      try {
        const response = await api.post(`/listicles/${listicle._id}/products`, newProduct);
        setProducts([...products, response.data]);
      } catch (error) {
        console.error('Error adding product:', error);
      }
    }

    // Function to handle local state changes for product fields
    function handleProductChange(e, index) {
      const { name, value } = e.target;
      const updatedProducts = [...products];
      updatedProducts[index] = { ...updatedProducts[index], [name]: value };
      setProducts(updatedProducts);
    }

    // Function to handle updating the product in the backend on blur
    async function handleProductBlur(e, index) {
      const { name, value } = e.target;
      const productId = products[index]._id;

      try {
        await updateProduct(productId, { [name]: value });
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }

    // Function to handle Wysiwyg editor blur events
    async function handleWysiwygBlur(index, fieldName, value) {
      const productId = products[index]._id;
      const updatedProducts = [...products];
      updatedProducts[index] = { ...updatedProducts[index], [fieldName]: value };
      setProducts(updatedProducts);

      try {
        await updateProduct(productId, { [fieldName]: value });
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }

    // Function to delete a product
    async function handleDeleteProduct(productId) {
      try {
        await api.delete(`/listicleProducts/${productId}`);
        setProducts((prevProducts) => prevProducts.filter((product) => product._id !== productId));
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }

    // Render logic
    if (!products.length) {
      return (
        <>
          <Typography className={classes.title} variant='h6' gutterBottom>
            Products
          </Typography>
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            component='span'
            onClick={handleAddProduct}
            className={classes.productIcon}
          >
            <AddIcon />
          </IconButton>
        </>
      );
    }

    const sortedProducts = products.sort((a, b) => a.order - b.order);
    const orderSelectOptions = Array.from({ length: products.length }, (_, i) => i + 1);

    return (
      <>
        <Typography className={classes.title} variant='h6' gutterBottom>
          Products
        </Typography>
        {sortedProducts.map((product, index) => {
          const { _id, title, link, image, order, ctaText, description } = product;

          return (
            <Box className={classes.product} key={_id}>
              <Box className={classes.productLeft}>
                <Typography variant='h6'>Title</Typography>
                <Wysiwyg
                  height={50}
                  initialValue={title}
                  onBlur={(value) => handleWysiwygBlur(index, 'title', value)}
                />
                <TextField
                  name='link'
                  label='Link'
                  value={link}
                  onChange={(e) => handleProductChange(e, index)}
                  className={classes.textField}
                  onBlur={(e) => handleProductBlur(e, index)}
                />
                <FormControl className={classes.textField}>
                  <InputLabel>Order</InputLabel>
                  <Select
                    name='order'
                    value={order}
                    label='Order'
                    onChange={(e) => handleProductChange(e, index)}
                    onBlur={(e) => handleProductBlur(e, index)}
                  >
                    {orderSelectOptions.map((n) => (
                      <MenuItem key={n} value={n}>
                        {n}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  name='ctaText'
                  label='CTA Text'
                  value={ctaText}
                  onChange={(e) => handleProductChange(e, index)}
                  className={classes.textField}
                  onBlur={(e) => handleProductBlur(e, index)}
                />
                <ImageInput
                  name='image'
                  label='Image'
                  value={image}
                  className={classes.textField}
                  onChange={(e) => handleProductChange(e, index)}
                  onBlur={(e) => handleProductBlur(e, index)}
                />
                <Typography variant='h6'>Description</Typography>
                <Wysiwyg
                  height={200}
                  initialValue={description}
                  onBlur={(value) => handleWysiwygBlur(index, 'description', value)}
                />
              </Box>
              <Box className={classes.productIcons}>
                <IconButton
                  color='secondary'
                  aria-label='delete product'
                  component='span'
                  size='small'
                  className={classes.productIcon}
                  onClick={() => handleDeleteProduct(_id)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  size='small'
                  color='primary'
                  aria-label='Add'
                  component='span'
                  onClick={handleAddProduct}
                  className={classes.productIcon}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </>
    );
  }

  return (
    <Container maxWidth='lg' className={classes.container}>
      {(listicleLoading || loading) && <LinearProgress className={classes.progress} />}
      {renderPageActionButtons()}
      <Paper className={classes.paper}>
        <TextField
          label='Id'
          value={listicle._id}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          className={classes.textField}
        />
        <TextField
          label='Name'
          helperText='Inner name for admin purposes. Not visible to the user.'
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() =>
            handleListicleUpdate({
              name,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Slug'
          helperText='URL slug for the listicle.'
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
          onBlur={() =>
            handleListicleUpdate({
              slug,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <ImageInput
          label='Logo'
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
          onBlur={() =>
            handleListicleUpdate({
              logo,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <ImageInput
          label='Favicon'
          value={favicon}
          onChange={(e) => setFavicon(e.target.value)}
          onBlur={() =>
            handleListicleUpdate({
              favicon,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Headline'
          value={headline}
          onChange={(e) => setHeadline(e.target.value)}
          onBlur={() =>
            handleListicleUpdate({
              headline,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Author Name'
          value={authorName}
          onChange={(e) => setAuthorName(e.target.value)}
          onBlur={() =>
            handleListicleUpdate({
              authorName,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <ImageInput
          label='Author Photo'
          value={authorPhoto}
          onChange={(e) => setAuthorPhoto(e.target.value)}
          onBlur={() =>
            handleListicleUpdate({
              authorPhoto,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <ImageInput
          label='Hero Image'
          value={heroImage}
          onChange={(e) => setHeroImage(e.target.value)}
          onBlur={() =>
            handleListicleUpdate({
              heroImage,
            })
          }
          fullWidth
          className={classes.textField}
        />

        <Typography className={classes.title}>Hero Text</Typography>
        <Wysiwyg
          height={300}
          initialValue={listicle.heroText}
          onBlur={(value) => handleWysiwygBlur('heroText', value)}
        />
        <Typography className={classes.title}>Update text</Typography>
        <Wysiwyg
          height={200}
          initialValue={listicle.updateText}
          onBlur={(value) => handleWysiwygBlur('updateText', value)}
        />

        {renderProducts()}
      </Paper>
    </Container>
  );
}
