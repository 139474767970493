import createDataContext from './createDataContext';
import { api } from '../api/api';

const offerReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        offers: action.payload,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const loadOffers = (dispatch) => async () => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.get('/offers', {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to load offers',
    });
  }
};

export const { Context, Provider } = createDataContext(
  offerReducer,
  {
    loadOffers,
  },
  {
    offers: [],
    error: '',
    loading: false,
  }
);
