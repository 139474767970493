import createDataContext from './createDataContext';
import { api } from '../api/api';

const reducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        listicles: action.payload,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const loadListicles = (dispatch) => async () => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.get('/listicles', {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to load listicles.',
    });
  }
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    loadListicles,
  },
  {
    listicles: [],
    error: '',
    loading: false,
  }
);
