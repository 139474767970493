import createDataContext from './createDataContext';
import { api } from '../api/api';

const orderReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        orders: action.payload,
      };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        loading: false,
        orders: state.orders.filter((item) => item._id !== action.payload),
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const loadOrders = (dispatch) => async (data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.get('/orders', {
      params: data,
    });
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to load orders',
    });
  }
};

const deleteOrder = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    await api.delete(`/orders/${id}`, {});
    dispatch({
      type: 'DELETE_SUCCESS',
      payload: id,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to delete order.',
    });
  }
};

export const { Context, Provider } = createDataContext(
  orderReducer,
  {
    loadOrders,
    deleteOrder,
  },
  {
    orders: [],
    error: '',
    loading: false,
  }
);
