import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Context as UserContext } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const {
    state: { user, error: userError },
    login,
  } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      localStorage.setItem('token', user.token);
      return history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function onFormSubmit(e) {
    e.preventDefault();
    login({
      email,
      password,
    });
  }

  return (
    <Container component='main' maxWidth='xs'>
      <form className={classes.paper} onSubmit={onFormSubmit}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h6'>
          Sign in
        </Typography>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label='Email Address'
          name='email'
          autoComplete='email'
          autoFocus
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='password'
          label='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete='password'
        />
        <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
          Sign In
        </Button>
        {userError && (
          <Typography variant='caption' color='error'>
            {userError}
          </Typography>
        )}
      </form>
    </Container>
  );
}
