import createDataContext from './createDataContext';
import { api } from '../api/api';

const freeProductReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        freeProduct: action.payload,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const loadFreeProduct = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.get(`/freeProducts/${id}`, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to load free product.',
    });
  }
};

const updateFreeProduct = (dispatch) => async (id, data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.patch(`/freeProducts/${id}`, data, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to update free product.',
    });
  }
};

const createFreeProduct = (dispatch) => async (data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.post(`/freeProducts`, data, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });

    return response.data;
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to create free product.',
    });
  }
};

const deleteFreeProduct = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.delete(`/freeProducts/${id}`, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to delete free product.',
    });
  }
};

export const { Context, Provider } = createDataContext(
  freeProductReducer,
  {
    loadFreeProduct,
    createFreeProduct,
    updateFreeProduct,
    deleteFreeProduct,
  },
  {
    freeProduct: null,
    error: '',
    loading: false,
  }
);
