export function getPriceWithCurrency(price, locale = 'en-US', currency = 'USD') {
  // if price has $ sign remove it first
  if (typeof price === 'string' && price.includes('$')) {
    price = price.replace('$', '');
  }

  return (price * 1).toLocaleString(locale, { style: 'currency', currency });
}

export function getProductDefaultImage(product) {
  if (!product.images || product.images.length === 0) {
    return '';
  }

  return product.images.find((item) => item.is_default === 1)?.path || product.images[0].path;
}

export function getDiscountedPrice(price, discount) {
  return price - (price * discount) / 100;
}

export function getSubscriptionPrice(price) {
  return getDiscountedPrice(price, 15);
}

export function getCookie(cookie, cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
