import React, { useContext, useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { api } from '../../api/api';
import { Context as UserContext } from '../../context/UserContext';

import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Container, Card, CardContent, Typography, Box } from '@material-ui/core';
import ChipsList from '../../components/ChipsList/ChipsList';

const useStyles = makeStyles((theme) => ({
  routerIds: {
    marginTop: theme.spacing(2),
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    minWidth: 275,
    margin: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  circleBar: {
    width: '75px',
    height: '75px',
    alignSelf: 'center',
    marginTop: theme.spacing(2),
  },
}));

const getPriceWithCurrency = (value) => `$${parseFloat(value).toFixed(2)}`;

export default function PaymentRoutersPage({ onRender }) {
  const classes = useStyles();
  const [routers, setRouters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [routerIds, setRouterIds] = useState([]);

  const {
    state: { user },
  } = useContext(UserContext);

  async function loadPaymentRouters() {
    setLoading(true);
    const response = await api.get('/paymentRouters', {});

    const sortedRouters = response.data.sort((a, b) => {
      const percentageA = (a.current_monthly_amount / a.monthly_cap) * 100;
      const percentageB = (b.current_monthly_amount / b.monthly_cap) * 100;

      // Sort in descending order of percentage
      return percentageB - percentageA;
    });

    setRouters(sortedRouters);
    setLoading(false);
  }

  async function loadPaymentRouterIds() {
    setLoading(true);
    const response = await api.get('/paymentRouterIds', {});
    setRouterIds(response.data);
    setLoading(false);
  }

  async function updateRouterIds(newItems) {
    setLoading(true);
    const response = await api.put('/paymentRouterIds', { ids: newItems });
    setRouterIds(response.data);
    setLoading(false);
    loadPaymentRouters();
  }

  useEffect(() => {
    if (user?.user.isSuperAdmin) {
      loadPaymentRouters();
      loadPaymentRouterIds();
      onRender('Payment Routers');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const calculatePercentage = (monthly_cap, current_monthly_amount) => {
    if (monthly_cap === 0) {
      return 0;
    }
    return (current_monthly_amount / monthly_cap) * 100;
  };

  return (
    <>
      {loading && <LinearProgress />}

      <Container className={classes.container}>
        <div className={classes.routerIds}>
          <ChipsList
            items={routerIds.map((item) => item.id)}
            onUpdate={(newItems) => updateRouterIds(newItems)}
            title='Payment Router Ids'
          />
        </div>
        <Box className={classes.cards}>
          {routers.map((router, index) => (
            <Card className={classes.card} key={index}>
              <CardContent className={classes.cardContent}>
                <Typography variant='h6' component='h2'>
                  {router.name} (ID: {router.router_id})
                </Typography>

                <Typography variant='body2'>
                  Global Monthly Cap: {getPriceWithCurrency(router.global_monthly_cap)}
                </Typography>
                <Typography variant='body2'>
                  Monthly Cap: {getPriceWithCurrency(router.monthly_cap)}
                </Typography>
                <Typography variant='body2'>
                  Current Monthly Amount: {getPriceWithCurrency(router.current_monthly_amount)}
                </Typography>
                <Typography variant='body2'>
                  Current Monthly Charges: {getPriceWithCurrency(router.current_monthly_charges)}
                </Typography>

                <Box className={classes.circleBar}>
                  <CircularProgressbar
                    value={calculatePercentage(router.monthly_cap, router.current_monthly_amount)}
                    text={`${Math.round(
                      calculatePercentage(router.monthly_cap, router.current_monthly_amount)
                    )}%`}
                    styles={buildStyles({
                      pathColor:
                        calculatePercentage(router.monthly_cap, router.current_monthly_amount) > 90
                          ? 'red'
                          : calculatePercentage(router.monthly_cap, router.current_monthly_amount) > 70
                          ? 'yellow'
                          : 'green',
                      textColor: 'black',
                    })}
                    strokeWidth={10} // Adjust the thickness of the circular progress bar
                    style={{ width: '150px', height: '150px' }} // Set the size to 150px
                  />
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Container>
    </>
  );
}
