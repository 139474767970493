import createDataContext from './createDataContext';
import { api } from '../api/api';

const analyticsReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        analytics: action.payload,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const loadAnalytics = (dispatch) => async (data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.get('/analytics', {
      params: data,
    });
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to load analytics',
    });
  }
};

export const { Context, Provider } = createDataContext(
  analyticsReducer,
  {
    loadAnalytics,
  },
  {
    analytics: {
      specialOffersTakeRate: [],
      freeProductsTakeRate: [],
      ordersTotal: 0,
    },
    error: '',
    loading: false,
  }
);
