import createDataContext from './createDataContext';
import { api } from '../api/api';

const crossSellsReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        crossSell: action.payload,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const loadCrossSell = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.get(`/crossSells/${id}`, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to load cross-sell.',
    });
  }
};

const updateCrossSell = (dispatch) => async (id, data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.patch(`/crossSells/${id}`, data, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to update cross-sell.',
    });
  }
};

const createCrossSell = (dispatch) => async (data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.post(`/crossSells`, data, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });

    return response.data;
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to create cross-sell.',
    });
  }
};

const deleteCrossSell = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.delete(`/crossSells/${id}`, {});
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to delete cross-sell.',
    });
  }
};

export const { Context, Provider } = createDataContext(
  crossSellsReducer,
  {
    loadCrossSell,
    createCrossSell,
    updateCrossSell,
    deleteCrossSell,
  },
  {
    crossSell: null,
    error: '',
    loading: false,
  }
);
