import createDataContext from './createDataContext';
import { api } from '../api/api';

const userReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        user: action.payload,
        isTokenValid: true,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
        isTokenValid: false,
      };
    default:
      return state;
  }
};

const login = (dispatch) => async (data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.post('/users/login', data);
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to login',
    });
  }
};

const logout = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    await api.post('/users/logout', {
      id,
      token: localStorage.getItem('token'),
    });
    dispatch({
      type: 'SUCCESS',
      payload: null,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to logout user.',
    });
  }
};

const validate = (dispatch) => async (data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.post('/users/validate', data);
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to validate user. Please login',
    });
  }
};

export const { Context, Provider } = createDataContext(
  userReducer,
  {
    login,
    logout,
    validate,
  },
  {
    user: null,
    error: false,
    loading: false,
    isTokenValid: true,
  }
);
